import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import WorkingProcessSection from '../containers/WorkingProcess';
import CTASection from '../containers/CTA';
import OnPremiseOrSaaSSection from '../containers/OnPremiseOrSaaS';
import PricingSection from '../containers/Pricing';
import FaqSection from '../containers/Faq';
import TextBoxSection from '../containers/TextBox';
import { useTranslation, Trans } from 'react-i18next';
import BannerSection from '../containers/Banner';

const Content = styled.div`
  font-family: 'Roboto', sans-serif;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        ENTERPRISE_BUSINESS_BENEFITS {
          title
          alt
          description
          image {
            publicURL
          }
        }
        ENTERPRISE_SECURITY_BENEFITS {
          title
          alt
          description
          image {
            publicURL
          }
        }
        ENTERPRISE_FAQ_DATA {
          title
          description
          expend
        }
        ENTERPRISE_PRICING_TABLE {
          id
          hostingType
          name
          titleImage {
            publicURL
          }
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
            link
          }
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Top Enterprise Password Management Solution')}
          lang={pageContext.lang}
          description={t(
            'With the Psono Password management solution companies can manage and audit the usage of privileged accounts and give different users the right access.'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            pageContext={pageContext}
            topText={t('All enterprise features free up to 10 users')}
            titleText={t('Enterprise password manager')}
            descriptionText={t(
              "Manage and audit the usage of privileged accounts in your company with Psono's enterprise password management solution."
            )}
            primeLink={'mailto:sales@psono.com'}
            primeText={t('Contact Sales').toUpperCase()}
            downloadLink={
              'https://doc.psono.com/admin/installation/install-preparation.html'
            }
            downloadText={t('INSTALL')}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <Content>
                <TextBoxSection
                  description={t('How can Psono help your company?')}
                  text={
                    <React.Fragment>
                      <Trans i18nKey="psonoEnterpriseDescription1">
                        Psono is an enterprise password management solution that
                        helps to solve the <b>privileged access</b> problem.
                        Employees can create, store and share secrets, such as
                        passwords for bank accounts, keys for IT systems and
                        documents... with <b>cryptographic security</b> thanks
                        to Psono’s password vault.
                      </Trans>{' '}
                      <Trans i18nKey="psonoEnterpriseDescription2">
                        <b>Protect sensitive information</b> when you share them
                        with team members or third parties. It empowers firms to{' '}
                        <b>control</b> and <b>audit</b> access to secrets and{' '}
                        <b>stay compliant</b> with security standards, such as
                        SOX, HIPAA, and PCI-DSS. Employees can access enterprise
                        passwords and interact with systems <b>faster</b>. The
                        possibility to automate processes such as password
                        rotations and deployments <b>reduces costs</b>. Psono
                        enterprise password management software makes working
                        faster and easier and takes the strain of remembering
                        and sharing passwords off of employees.
                      </Trans>
                    </React.Fragment>
                  }
                />
                <br />
                <WorkingProcessSection
                  title={t(
                    'How can centralized password storage help your business?'
                  )}
                  description={t('Business Benefits')}
                  items={Data.saasModernJson.ENTERPRISE_BUSINESS_BENEFITS}
                  pageContext={pageContext}
                />
                <OnPremiseOrSaaSSection />
                <WorkingProcessSection
                  title={t(
                    'What enterprise password management with Psono improves the security of your business?'
                  )}
                  description={t('Security Benefits')}
                  items={Data.saasModernJson.ENTERPRISE_SECURITY_BENEFITS}
                  pageContext={pageContext}
                />
                <PricingSection
                  sectionInfo={Data.saasModernJson.ENTERPRISE_PRICING_TABLE}
                />
                <FaqSection data={Data.saasModernJson.ENTERPRISE_FAQ_DATA} />
                <CTASection
                  content={t('Interested to hear more?')}
                  buttonLink={'mailto:sales@psono.com'}
                  buttonTitle={t('Contact Sales')}
                />
              </Content>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
